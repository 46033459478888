window.process = {
  nextTick: function (fn) { setTimeout(fn, 0); },
  env: { NODE_ENV: 'development' },
  _readableState: {}
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import store from './store';
import App from './App';
import theme from './theme';
import { createStreamWrapper } from './utils/streamFix';

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>
);

createStreamWrapper();