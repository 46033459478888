import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/RoomInvite.css';

function RoomInvite() {
    const { inviteCode } = useParams();
    const navigate = useNavigate();
    const [roomInfo, setRoomInfo] = useState({
        name: "",
        memberCount: 0,
        channelCount: 0,
        ownerName: ""
    });

    useEffect(() => {
        const fetchRoomInfo = async () => {
            const response = await fetch(`https://api.laktalk.com/api/rooms/invite/${inviteCode}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const data = await response.json();
            if (data.success) {
                setRoomInfo(data.room);
            }
        };
        fetchRoomInfo();
    }, [inviteCode]);

    const joinRoom = async () => {
        const response = await fetch(`https://api.laktalk.com/api/rooms/join/${inviteCode}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        
        const data = await response.json();
        if (data.success) {
            navigate('/');
        }
    };

    return (
        <div className="invite-container">
            <div className="invite-card">
                <div className="room-banner">
                    <h1>{roomInfo.name}</h1>
                    <p className="owner-name">Oda Sahibi: {roomInfo.ownerName}</p>
                </div>
                <div className="room-stats">
                    <div className="stat-item">
                        <div className="stat-value">{roomInfo.memberCount}</div>
                        <div className="stat-label">Üye</div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-value">{roomInfo.channelCount}</div>
                        <div className="stat-label">Kanal</div>
                    </div>
                </div>
                <button className="join-button" onClick={joinRoom}>
                    Odaya Katıl
                </button>
            </div>
        </div>
    );
}

export default RoomInvite;
