const API_URL = 'https://api.laktalk.com';

export const endpoints = {
    login: `${API_URL}/api/auth/login`,
    register: `${API_URL}/api/auth/register`,
    messages: `${API_URL}/api/messages`,
    users: `${API_URL}/api/users`,
    rooms: `${API_URL}/api/rooms`,
    channels: `${API_URL}/api/channels`
};

export const socketURL = 'https://api.laktalk.com';
export default endpoints;
