import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import '../styles/ChatRoom.css';

function ChatRoom({ channelId }) {
    const user = useSelector(state => state.auth.user);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [typingUsers, setTypingUsers] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const typingTimeoutRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const TypingIndicator = ({ typingUsers }) => {
      if (typingUsers.size === 0) return null;
      
      const users = Array.from(typingUsers);
      let text = '';
      
      if (users.length === 1) {
          text = `${users[0]} yazıyor...`;
      } else if (users.length === 2) {
          text = `${users[0]} ve ${users[1]} yazıyor...`;
      } else {
          text = `${users.length} kişi yazıyor...`;
      }
      
      return (
          <div className="typing-indicator">
              <span>{text}</span>
          </div>
      );
  };

    // Mesajları getirme
    const fetchMessages = async () => {
        try {
            const response = await fetch(`https://api.laktalk.com/api/messages/${channelId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const data = await response.json();
            if (data.success) {
                setMessages(data.messages);
            }
        } catch (error) {
            console.error('Mesajlar yüklenirken hata:', error);
        }
    };

    // Yazma durumunu kontrol etme
    const checkTypingUsers = async () => {
        try {
            const response = await fetch(`https://api.laktalk.com/api/channels/${channelId}/typing`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const data = await response.json();
            if (data.success) {
                const currentTypingUsers = new Set(data.typingUsers.filter(username => username !== user.username));
                setTypingUsers(currentTypingUsers);
            }
        } catch (error) {
            console.log('Typing check error:', error);
        }
    };

    // Yazma durumunu bildirme
    const emitTyping = async () => {
        try {
            await fetch(`https://api.laktalk.com/api/channels/${channelId}/typing`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ username: user.username })
            });
        } catch (error) {
            console.log('Typing notification error:', error);
        }
    };

    // Input değişikliğini yönetme
    const handleTyping = (e) => {
        setNewMessage(e.target.value);
        if (e.target.value) {
            emitTyping();
            if (typingTimeoutRef.current) {
                clearTimeout(typingTimeoutRef.current);
            }
            typingTimeoutRef.current = setTimeout(() => {
                emitTyping();
            }, 2000);
        }
    };

    // Mesaj gönderme
    const sendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || isLoading) return;

        setIsLoading(true);
        try {
            const response = await fetch(`https://api.laktalk.com/api/messages/${channelId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ content: newMessage })
            });

            const data = await response.json();
            if (data.success) {
                setNewMessage('');
                fetchMessages();
            }
        } catch (error) {
            console.error('Mesaj gönderilirken hata:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Enter ile gönderme
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage(e);
        }
    };

    // Interval ve effect yönetimi
    useEffect(() => {
        fetchMessages();
        const messageInterval = setInterval(fetchMessages, 1000);
        const typingInterval = setInterval(checkTypingUsers, 1000);

        return () => {
            clearInterval(messageInterval);
            clearInterval(typingInterval);
        };
    }, [channelId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className="chatroom-container">
            <div className="messages-container">
                {messages.map(message => (
                    <div key={message.id} className="message">
                        <img
                            src={message.avatar || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'}
                            alt="avatar"
                            className="message-avatar"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y';
                            }}
                        />
                        <div className="message-content">
                            <div className="message-header">
                                <span className="message-username">{message.username}</span>
                                <span className="message-time">
                                    {new Date(message.created_at).toLocaleTimeString()}
                                </span>
                            </div>
                            <p>{message.content}</p>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
                <TypingIndicator typingUsers={typingUsers} />
            </div>
            <form onSubmit={sendMessage} className="message-form">
                <input
                    type="text"
                    value={newMessage}
                    onChange={handleTyping}
                    onKeyPress={handleKeyPress}
                    placeholder="Mesajınızı yazın..."
                    disabled={isLoading}
                />
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Gönderiliyor...' : 'Gönder'}
                </button>
            </form>
        </div>
    );
}

export default ChatRoom;
