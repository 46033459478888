const initialState = {
  userRooms: [],
  selectedRoom: null,
  channels: [],
  loading: false,
  error: null
};

export default function roomReducer(state = initialState, action) {
  switch (action.type) {
      case 'FETCH_ROOMS_SUCCESS':
          return {
              ...state,
              userRooms: action.payload,
              loading: false
          };
      case 'CREATE_ROOM_SUCCESS':
          return {
              ...state,
              userRooms: [...state.userRooms, action.payload]
          };
      case 'SELECT_ROOM':
          return {
              ...state,
              selectedRoom: action.payload
          };
    case 'FETCH_CHANNELS_SUCCESS':
      return {
        ...state,
        channels: action.payload
      };
    default:
      return state;
  }
};
