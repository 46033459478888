import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';  // Düzeltilmiş import
import rootReducer from './reducers';

const initialState = {
  auth: {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null
  },
  rooms: {
    list: [],
    loading: false,
    error: null
  },
  channels: {
    list: [],
    loading: false,
    error: null
  }
};

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk)
);

export default store;
