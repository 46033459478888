import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchRooms, selectRoom, fetchChannels, createRoom, createChannel } from '../store/actions/roomActions';
import { logout } from '../store/actions/authActions';
import ChatRoom from './ChatRoom';
import VoiceChannel from '../components/VoiceChannel';
import '../styles/Home.css';

function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showChannelModal, setShowChannelModal] = useState(false);
    const [newRoomName, setNewRoomName] = useState('');
    const [newChannelName, setNewChannelName] = useState('');
    const [channelType, setChannelType] = useState('text');
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [showSettings, setShowSettings] = useState(false);

    const { userRooms = [], selectedRoom, channels = [] } = useSelector(state => state.rooms);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchRooms());
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
      if (selectedRoom) {
          const fetchUserRole = async () => {
              try {
                  const token = localStorage.getItem('token');
                  const response = await fetch(`https://api.laktalk.com/api/rooms/${selectedRoom.id}/member-role`, {
                      headers: {
                          'Authorization': `Bearer ${token}`,
                          'Content-Type': 'application/json'
                      }
                  });
                  const data = await response.json();
                  if (data.success) {
                      setUserRole(data.role);
                  }
              } catch (error) {
                  console.error('Role fetch error:', error);
              }
          };
          fetchUserRole();
      }
  }, [selectedRoom]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showSettings && !event.target.closest('.settings-container')) {
                setShowSettings(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showSettings]);

    const handleRoomSelect = (room) => {
        dispatch(selectRoom(room));
        dispatch(fetchChannels(room.id));
        setSelectedChannel(null);
    };

    const handleChannelSelect = (channel) => {
        setSelectedChannel(channel);
    };

    const handleCreateRoom = async () => {
        if (newRoomName.trim()) {
            await dispatch(createRoom({ name: newRoomName }));
            setNewRoomName('');
            setShowCreateModal(false);
            dispatch(fetchRooms());
        }
    };

    const handleCreateChannel = async () => {
        if (newChannelName.trim() && selectedRoom) {
            await dispatch(createChannel(selectedRoom.id, {
                name: newChannelName,
                type: channelType
            }));
            setNewChannelName('');
            setChannelType('text');
            setShowChannelModal(false);
            dispatch(fetchChannels(selectedRoom.id));
        }
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    const generateInviteLink = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`https://api.laktalk.com/api/rooms/${selectedRoom.id}/invite`, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          });
          
          const data = await response.json();
          if (data.success) {
              await navigator.clipboard.writeText(data.inviteLink);
              alert('Davet linki kopyalandı!');
          }
      } catch (error) {
          console.error('Invite link error:', error);
      }
  };
  
  const leaveRoom = async (roomId) => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.laktalk.com/api/rooms/${roomId}/leave`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();
        if (data.success) {
            dispatch(fetchRooms());
            setSelectedChannel(null);
        }
    } catch (error) {
        console.error('Leave room error:', error);
    }
};

    const deleteRoom = async (roomId) => {
      if (window.confirm('Bu odayı silmek istediğinize emin misiniz?')) {
          try {
              const token = localStorage.getItem('token');
              const response = await fetch(`https://api.laktalk.com/api/rooms/${roomId}`, {
                  method: 'DELETE',
                  headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  }
              });
  
              const data = await response.json();
              if (data.success) {
                  dispatch(fetchRooms());
                  setSelectedChannel(null);
              }
          } catch (error) {
              console.error('Delete room error:', error);
          }
      }
  };  

    return (
        <div className="home-layout">
            {/* Rooms Header */}
            <div className="rooms-header">
                <button className="create-room-btn" onClick={() => setShowCreateModal(true)}>+</button>
                
                <div className="rooms-list">
                    {userRooms.map(room => (
                        <div
                            key={room.id}
                            className={`room-tab ${selectedRoom?.id === room.id ? 'active' : ''}`}
                            onClick={() => handleRoomSelect(room)}
                        >
                            {room.name}
                        </div>
                    ))}
                </div>

                <div className="settings-container">
                    <button className="settings-button" onClick={() => setShowSettings(!showSettings)}>
                        ⚙️
                    </button>
                    
                    {showSettings && (
                        <div className="settings-menu">
                            <button onClick={() => navigate('/profile')}>
                                👤 Profil
                            </button>
                            <button onClick={() => navigate('/settings')}>
                                ⚙️ Ayarlar
                            </button>
                            
                            {selectedRoom && (
                                (user.id === selectedRoom.owner_id || userRole === 'admin') ? (
                                    <button className="delete-room-btn" onClick={() => deleteRoom(selectedRoom.id)}>
                                        🗑️ Odayı Sil
                                    </button>
                                ) : (
                                    <button className="leave-room-btn" onClick={() => leaveRoom(selectedRoom.id)}>
                                        🚪 Odadan Ayrıl
                                    </button>
                                )
                            )}
                            
                            <button onClick={handleLogout}>
                                🚪 Çıkış Yap
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {/* Main Content */}
            <div className="main-content">
                {selectedRoom && (
                    <>
                        <div className="channels-sidebar">
                            <div className="channels-header">
                                <h3>{selectedRoom.name}</h3>
                                <div className="room-actions">
                                    {(selectedRoom.owner_id === user?.id || userRole === 'admin') && (
                                        <button className="create-channel-btn" onClick={() => setShowChannelModal(true)}>
                                            + Kanal Ekle
                                        </button>
                                    )}
                                    <button className="invite-btn" onClick={generateInviteLink}>
                                        📨 Davet Et
                                    </button>
                                </div>
                            </div>

                            <div className="channels-list">
                                <div className="channel-category">
                                    <h4>📝 Metin Kanalları</h4>
                                    {channels.filter(channel => channel.type === 'text').map(channel => (
                                        <div
                                            key={channel.id}
                                            className={`channel-item ${selectedChannel?.id === channel.id ? 'active' : ''}`}
                                            onClick={() => handleChannelSelect(channel)}
                                        >
                                            # {channel.name}
                                        </div>
                                    ))}
                                </div>

                                <div className="channel-category">
                                    <h4>🎤 Sesli Kanallar</h4>
                                    {channels.filter(channel => channel.type === 'voice').map(channel => (
                                        <div
                                            key={channel.id}
                                            className={`channel-item ${selectedChannel?.id === channel.id ? 'active' : ''}`}
                                            onClick={() => handleChannelSelect(channel)}
                                        >
                                            🔊 {channel.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="chat-area">
                            {selectedChannel ? (
                                <div className="channel-content">
                                    <h2>{selectedChannel.type === 'text' ? '#' : '🔊'} {selectedChannel.name}</h2>
                                    {selectedChannel.type === 'text' ? (
                                        <ChatRoom channelId={selectedChannel.id} />
                                    ) : (
                                        <VoiceChannel channelId={selectedChannel.id} />
                                    )}
                                </div>
                            ) : (
                                <div className="select-channel-message">
                                    👈 Bir kanal seçin
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>

            {/* Modals */}
            {showCreateModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Yeni Oda Oluştur</h3>
                        <input
                            type="text"
                            value={newRoomName}
                            onChange={(e) => setNewRoomName(e.target.value)}
                            placeholder="Oda adı"
                        />
                        <div className="modal-buttons">
                            <button onClick={handleCreateRoom}>Oluştur</button>
                            <button onClick={() => setShowCreateModal(false)}>İptal</button>
                        </div>
                    </div>
                </div>
            )}

            {showChannelModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Yeni Kanal Oluştur</h3>
                        <input
                            type="text"
                            value={newChannelName}
                            onChange={(e) => setNewChannelName(e.target.value)}
                            placeholder="Kanal adı"
                        />
                        <select
                            value={channelType}
                            onChange={(e) => setChannelType(e.target.value)}
                            className="channel-type-select"
                        >
                            <option value="text">Metin Kanalı</option>
                            <option value="voice">Sesli Kanal</option>
                        </select>
                        <div className="modal-buttons">
                            <button onClick={handleCreateChannel}>Oluştur</button>
                            <button onClick={() => setShowChannelModal(false)}>İptal</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;
