import { combineReducers } from 'redux';
import authReducer from './authReducer';
import roomReducer from './roomReducer';
import channelReducer from './channelReducer';

export default combineReducers({
  auth: authReducer,
  rooms: roomReducer,
  channels: channelReducer
});
