const initialState = {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null
  };
  
  export default function authReducer(state = initialState, action) {
    switch (action.type) {
      case 'LOGIN_REQUEST':
        return { ...state, loading: true };
        case 'LOGIN_SUCCESS':
          return {
              ...state,
              user: action.payload,
              isAuthenticated: true,
              loading: false
          };
      case 'LOGIN_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case 'LOGOUT':
        return initialState;

        case 'UPDATE_PROFILE_SUCCESS':
            return {
                ...state,
                user: action.payload,
                error: null
            };
        
        case 'UPDATE_PROFILE_ERROR':
            return {
                ...state,
                error: action.payload
            };
            case 'FETCH_PROFILE_SUCCESS':
            return {
                ...state,
                user: action.payload,
                loading: false
            };
        case 'UPDATE_AVATAR_SUCCESS':
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar: action.payload
                }
            };

        case 'UPDATE_AVATAR_ERROR':
            return {
                ...state,
                error: action.payload
            };

            
      default:
        return state;
    }
  }
  
  