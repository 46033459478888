import api from '../../services/api';

export const login = (credentials) => async (dispatch) => {
    try {
        const response = await fetch('https://api.laktalk.com/api/auth/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
				},
			credentials: 'include',
			body: JSON.stringify(userData)
		});


        const data = await response.json();
        if (data.success) {
            localStorage.setItem('token', data.token);
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: data.user
            });
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

export const register = (userData) => async (dispatch) => {
    dispatch({ type: 'REGISTER_REQUEST' });
    try {
        const response = await fetch('https://api.laktalk.com/api/auth/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(userData)
        });
        
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || 'Kayıt işlemi başarısız');
        }
        
        localStorage.setItem('token', data.token);
        dispatch({ type: 'REGISTER_SUCCESS', payload: data });
        return data;
    } catch (error) {
        dispatch({ type: 'REGISTER_FAILURE', payload: error.message });
        throw error;
    }
};


export const checkAuthStatus = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  if (token) {
      try {
          const response = await fetch('https://api.laktalk.com/api/auth/verify', {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          });
          const data = await response.json();
          dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      } catch (error) {
          localStorage.removeItem('token');
          dispatch({ type: 'LOGOUT' });
      }
  }
};

export const updateProfile = (formData) => async (dispatch) => {
    try {
        const token = localStorage.getItem('token');
        
        // FormData oluştur
        const data = new FormData();
        data.append('email', formData.email);
        if (formData.currentPassword) {
            data.append('currentPassword', formData.currentPassword);
        }
        if (formData.newPassword) {
            data.append('newPassword', formData.newPassword);
        }
        if (formData.avatar instanceof File) {
            data.append('avatar', formData.avatar);
        }

        const response = await fetch('https://api.laktalk.com/api/auth/profile/update', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: data
        });

        const result = await response.json();
        
        if (result.success) {
            dispatch({
                type: 'UPDATE_PROFILE_SUCCESS',
                payload: result.user
            });
            return true;
        }
        return false;
    } catch (error) {
        console.log('Profile update error:', error);
        return false;
    }
};

export const fetchUserProfile = () => async (dispatch) => {
    try {
        const response = await fetch('https://api.laktalk.com/api/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });

        const data = await response.json();
        
        if (response.ok) {
            dispatch({
                type: 'FETCH_PROFILE_SUCCESS',
                payload: data.user
            });
        }
    } catch (error) {
        dispatch({
            type: 'FETCH_PROFILE_ERROR',
            payload: error.message
        });
    }
};

export const checkAuth = () => async (dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const response = await fetch('https://api.laktalk.com/api/auth/profile', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.success) {
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    payload: data.user
                });
            }
        } catch (error) {
            localStorage.removeItem('token');
        }
    }
};

export const updateAvatar = (formData) => async (dispatch) => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.laktalk.com/api/auth/update-avatar', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.success) {
            dispatch({
                type: 'UPDATE_AVATAR_SUCCESS',
                payload: data.user
            });
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

export const fetchProfile = () => async (dispatch) => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.laktalk.com/api/auth/profile', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (data.success) {
            dispatch({
                type: 'FETCH_PROFILE_SUCCESS',
                payload: data.user
            });
        }
    } catch (error) {
        console.log('Profile fetch error:', error);
    }
};

export const loginSuccess = (userData) => ({
    type: 'LOGIN_SUCCESS',
    payload: userData
});

export const logout = () => {
  localStorage.removeItem('token');
  return { type: 'LOGOUT' };
};
