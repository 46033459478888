// Stream hatalarını yakalayan ve gizleyen wrapper
export const createStreamWrapper = () => {
    // Global hata yakalayıcı
    window.addEventListener('error', (event) => {
        if (event.message?.includes('_readableState')) {
            event.preventDefault();
            return false;
        }
    }, true);

    // Promise hataları için
    window.addEventListener('unhandledrejection', (event) => {
        if (event.reason?.message?.includes('_readableState')) {
            event.preventDefault();
            return false;
        }
    });

    // Stream metodlarını güvenli hale getir
    const safeStreamMethods = {
        emitReadable_: (stream) => {
            try {
                if (!stream || !stream._readableState) return;
                const state = stream._readableState;
                
                if (!state.destroyed && (state.length || state.ended)) {
                    stream.emit('readable');
                    state.emittedReadable = false;
                }
            } catch {}
        }
    };

    return safeStreamMethods;
};
