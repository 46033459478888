import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchProfile } from '../store/actions/authActions';
import '../styles/Profile.css';

function Profile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            dispatch(fetchProfile());
        }
    }, [dispatch, isAuthenticated, navigate]);

    return (
        <div className="profile-page">
            <div className="profile-container">
                <div className="profile-header">
                    <h1>Kullanıcı Bilgileri</h1>
                </div>

                <div className="profile-content">
                    <div className="avatar-section">
                        <div className="profile-avatar">
                            <img 
                                src={user?.avatar || 'https://via.placeholder.com/150'} 
                                alt="Profile" 
                                className="avatar-image"
                            />
                        </div>
                    </div>

                    <div className="info-card">
                        <div className="info-item">
                            <span className="info-label">👤 Kullanıcı Adı</span>
                            <span className="info-value">{user?.username}</span>
                        </div>
                        <div className="info-item">
                            <span className="info-label">📧 E-posta</span>
                            <span className="info-value">{user?.email}</span>
                        </div>
                    </div>

                    <div className="stats-section">
                        <h2>İstatistikler</h2>
                        <div className="stats-grid">
                            <div className="stat-card">
                                <span className="stat-value">{user?.roomCount || 0}</span>
                                <span className="stat-label">Toplam Oda</span>
                            </div>
                            <div className="stat-card">
                                <span className="stat-value">{user?.memberCount || 0}</span>
                                <span className="stat-label">Üye Olunan Oda</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
