const initialState = {
  list: [],
  currentChannel: null,
  loading: false,
  error: null
};

export default function channelReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_CHANNELS_REQUEST':
    case 'CREATE_CHANNEL_REQUEST':
      return { ...state, loading: true };

    case 'FETCH_CHANNELS_SUCCESS':
      return {
        ...state,
        loading: false,
        list: action.payload,
        error: null
      };

    case 'CREATE_CHANNEL_SUCCESS':
      return {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
        error: null
      };

    case 'FETCH_CHANNELS_FAILURE':
    case 'CREATE_CHANNEL_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case 'SET_CURRENT_CHANNEL':
      return {
        ...state,
        currentChannel: action.payload
      };

    default:
      return state;
  }
}
