import api from '../../services/api';

export const selectRoom = (room) => {
    return {
        type: 'SELECT_ROOM',
        payload: room
    };
};

export const fetchRooms = () => async (dispatch) => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.laktalk.com/api/rooms/user', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();
        if (data.success) {
            dispatch({
                type: 'FETCH_ROOMS_SUCCESS',
                payload: data.rooms
            });
        }
    } catch (error) {
        console.error('Error fetching rooms:', error);
    }
};

export const createRoom = (roomData) => async (dispatch) => {
    try {
        const response = await fetch('https://api.laktalk.com/api/rooms', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(roomData)
        });

        const data = await response.json();
        if (data.success) {
            dispatch({ type: 'CREATE_ROOM_SUCCESS', payload: data.room });
            return data.room;
        }
    } catch (error) {
        console.error('Oda oluşturulamadı:', error);
    }
};

export const joinRoom = (roomId) => async (dispatch) => {
  dispatch({ type: 'JOIN_ROOM_REQUEST' });
  try {
    const { data } = await api.post(`/rooms/${roomId}/join`);
    dispatch({ type: 'JOIN_ROOM_SUCCESS', payload: data });
  } catch (error) {
    dispatch({ type: 'JOIN_ROOM_FAILURE', payload: error.message });
  }
};

export const createChannel = (roomId, channelData) => async (dispatch) => {
    const token = localStorage.getItem('token');
    
    try {
        const response = await fetch(`https://api.laktalk.com/api/rooms/${roomId}/channels`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(channelData)
        });

        const data = await response.json();
        
        if (data.success) {
            dispatch(fetchChannels(roomId));
            return data.channel;
        }
    } catch (error) {
        console.error('Kanal oluşturma hatası:', error);
    }
};

export const fetchChannels = (roomId) => async (dispatch) => {
    const token = localStorage.getItem('token');
    
    try {
        const response = await fetch(`https://api.laktalk.com/api/rooms/${roomId}/channels`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        
        if (data.success) {
            dispatch({
                type: 'FETCH_CHANNELS_SUCCESS',
                payload: data.channels
            });
        }
    } catch (error) {
        console.error('Kanalları getirme hatası:', error);
    }
};

// Room actions'a rol bilgisini ekleyelim
export const fetchRoomDetails = (roomId) => async (dispatch) => {
    const token = localStorage.getItem('token');
    
    try {
        const response = await fetch(`https://api.laktalk.com/api/rooms/${roomId}/details`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        
        if (data.success) {
            dispatch({
                type: 'FETCH_ROOM_DETAILS_SUCCESS',
                payload: {
                    room: data.room,
                    userRole: data.userRole
                }
            });
        }
    } catch (error) {
        console.error('Oda detayları getirilemedi:', error);
    }
};
