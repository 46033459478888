import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import axios from 'axios';
import '../styles/VoiceChannel.css';

const AVATAR_PATH = '/uploads/avatars/'; // Kullanıcı avatarları için
const DEFAULT_AVATAR = '/assets/images/default-avatar.png'; // Varsayılan avatar

const VoiceChannel = ({ channelId }) => {
    const socketRef = useRef(null);
    const [activeParticipants, setActiveParticipants] = useState([]);
    const [currentSpeaker, setCurrentSpeaker] = useState(null);
    const user = useSelector(state => state.auth.user);
    const [isActive, setIsActive] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isPushToTalk, setIsPushToTalk] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [speakingUsers, setSpeakingUsers] = useState(new Set());

    const streamRef = useRef();
    const audioContextRef = useRef();
    const analyserRef = useRef();

    // Ses analizi kurulumu
    const setupVoiceDetection = (stream) => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyserRef.current);

        const checkAudio = () => {
            if (!analyserRef.current || !isActive) return;

            const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
            analyserRef.current.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((a, b) => a + b) / dataArray.length;

            if (average > 30 && !isMuted) {
                socketRef.current?.emit('speaking', user.id);
                setSpeakingUsers(prev => new Set(prev).add(user.id));
            } else {
                socketRef.current?.emit('stoppedSpeaking', user.id);
                setSpeakingUsers(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(user.id);
                    return newSet;
                });
            }

            requestAnimationFrame(checkAudio);
        };

        checkAudio();
    };

    const initializeSocket = () => {
        socketRef.current = io('https://api.laktalk.com', {
            transports: ['websocket'],
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            autoConnect: true
        });

        socketRef.current.on('connect', () => {
            console.log('Socket bağlantısı başarılı');
            socketRef.current.emit('getVoiceParticipants', channelId);
        });

        socketRef.current.on('voiceParticipants', (participants) => {
            console.log('Katılımcılar güncellendi:', participants);
            setActiveParticipants(participants);
        });

        socketRef.current.on('userSpeaking', (speakingUserId) => {
            setCurrentSpeaker(speakingUserId);
        });
    };

    useEffect(() => {
        initializeSocket();
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [channelId]);

    useEffect(() => {
        // Socket.IO bağlantısını oluştur
        socketRef.current = io('https://api.laktalk.com', {
            transports: ['websocket', 'polling'],
            reconnection: true
        });

        // Socket olaylarını dinle
        if (socketRef.current) {
            socketRef.current.on('voiceParticipants', (participants) => {
                setActiveParticipants(participants);
            });

            socketRef.current.on('userSpeaking', (speakingUserId) => {
                setCurrentSpeaker(speakingUserId);
            });

            socketRef.current.on('userStoppedSpeaking', () => {
                setCurrentSpeaker(null);
            });

            // Mevcut katılımcıları al
            socketRef.current.emit('getVoiceParticipants', channelId);
        }

        // Cleanup
        return () => {
            if (socketRef.current) {
                socketRef.current.off('voiceParticipants');
                socketRef.current.off('userSpeaking');
                socketRef.current.off('userStoppedSpeaking');
                socketRef.current.disconnect();
            }
        };
    }, [channelId]);

    // Bas-konuş özelliği
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.code === 'Space' && !isPushToTalk) {
                setIsPushToTalk(true);
                setIsMuted(false);
                if (streamRef.current) {
                    streamRef.current.getAudioTracks()[0].enabled = true;
                }
            }
        };

        const handleKeyUp = (e) => {
            if (e.code === 'Space' && isPushToTalk) {
                setIsPushToTalk(false);
                setIsMuted(true);
                if (streamRef.current) {
                    streamRef.current.getAudioTracks()[0].enabled = false;
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [isPushToTalk]);

    const handleJoinVoice = () => {
        setIsActive(true);
        socketRef.current.emit('joinVoice', {
            channelId,
            userId: user.id,
            username: user.username,
            avatar: user.avatar
        });
    };

    const handleLeaveVoice = () => {
        setIsActive(false);
        socketRef.current.emit('leaveVoice', user.id);
    };

    const peersRef = useRef({});
const [streams, setStreams] = useState(new Map());

const createPeerConnection = (targetUserId, stream) => {
    const peer = new RTCPeerConnection({
        iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:global.stun.twilio.com:3478' }
        ]
    });

    stream.getTracks().forEach(track => {
        peer.addTrack(track, stream);
    });

    peer.ontrack = (event) => {
        setStreams(prev => new Map(prev).set(targetUserId, event.streams[0]));
    };

    return peer;
};

    // Socket bağlantısı ve mikrofon erişimi
    const initializeVoiceChannel = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true
                }
            });
    
            streamRef.current = stream;
            setupVoiceDetection(stream);
    
            socketRef.current = io('https://api.laktalk.com', {
                transports: ['websocket', 'polling'],
                reconnection: true
            });
    
            socketRef.current.on('connect', () => {
                socketRef.current.emit('joinVoice', {
                    channelId,
                    userId: user.id,
                    username: user.username,
                    avatar: user.avatar
                });
            });
    
            // Yeni kullanıcı katıldığında
            socketRef.current.on('userJoined', async (userData) => {
                const peer = createPeerConnection(userData.userId, stream);
                peersRef.current[userData.userId] = peer;
                
                setParticipants(prev => [...prev, userData]);
            });
    
            // Kullanıcı ayrıldığında
            socketRef.current.on('userLeft', (userId) => {
                if (peersRef.current[userId]) {
                    peersRef.current[userId].close();
                    delete peersRef.current[userId];
                }
                setStreams(prev => {
                    const newStreams = new Map(prev);
                    newStreams.delete(userId);
                    return newStreams;
                });
                setParticipants(prev => prev.filter(p => p.userId !== userId));
            });
    
        } catch (error) {
            console.log('Ses başlatma hatası:', error);
        }
    };

    // Temizleme işlemleri
    const cleanup = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
        }
        if (socketRef.current) {
            socketRef.current.disconnect();
        }
        setParticipants([]);
        setSpeakingUsers(new Set());
    };

    useEffect(() => {
        if (isActive) {
            initializeVoiceChannel();
        } else {
            cleanup();
        }
        return cleanup;
    }, [isActive, channelId]);

    return (
        <div className="voice-channel">
            <div className="voice-header" key="voice-header">
                <h3>Sesli Sohbet</h3>
                <div className="voice-controls" key="voice-controls">
                    {isActive ? (
                        <div key="active-controls">
                            <button 
                                key="mute-button"
                                className={`control-button ${isMuted ? 'muted' : ''}`}
                                onClick={() => setIsMuted(!isMuted)}
                            >
                                {isMuted ? '🔇' : '🎤'}
                            </button>
                            <button 
                                key="leave-button"
                                className="control-button leave"
                                onClick={handleLeaveVoice}
                            >
                                📞 Ayrıl
                            </button>
                        </div>
                    ) : (
                        <button 
                            key="join-button"
                            className="control-button join"
                            onClick={handleJoinVoice}
                        >
                            📞 Katıl
                        </button>
                    )}
                </div>
            </div>
    
            <div className="participants-grid" key="participants-grid">
                {activeParticipants.map((participant) => (
                    <div 
                        key={`participant-${participant.userId}-${Date.now()}`}
                        className={`participant-card ${currentSpeaker === participant.userId ? 'speaking' : ''}`}
                    >
                        <div className="avatar-container" key={`avatar-${participant.userId}`}>
                            <img 
                                src={participant.avatar || '/assets/images/default-avatar.png'}
                                alt={participant.username}
                                className="user-avatar"
                                onError={(e) => e.target.src = '/assets/images/default-avatar.png'}
                            />
                        </div>
                        <span className="username" key={`username-${participant.userId}`}>
                            {participant.username}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
    
};

export default VoiceChannel;
