import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { register } from '../store/actions/authActions';
import endpoints from '../config/api';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: ''
  });
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await dispatch(register(formData));
      if (result.success) {
        navigate('/home');
      }
    } catch (err) {
      setError(err.message || 'Kayıt işlemi başarısız');
    }
  };
  

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 400, p: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>Kayıt Ol</Typography>
        
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        
        <TextField
          fullWidth
          label="Kullanıcı Adı"
          value={formData.username}
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
          sx={{ mb: 2 }}
        />
        
        <TextField
          fullWidth
          label="E-posta"
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          sx={{ mb: 2 }}
        />
        
        <TextField
          fullWidth
          label="Şifre"
          type="password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          sx={{ mb: 3 }}
        />
        
        <Button 
          fullWidth 
          variant="contained" 
          type="submit"
          sx={{ mb: 2 }}
        >
          Kayıt Ol
        </Button>
        
        <Button 
          fullWidth 
          variant="text"
          onClick={() => navigate('/login')}
        >
          Giriş Yap
        </Button>
      </Box>
    </Box>
  );
};

export default Register;
