import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { login } from '../store/actions/authActions';
import endpoints from '../config/api';
import api from '../services/api'; 

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await api.post('/auth/login', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        console.log('✅ Login başarılı:', response.data);
    } catch (error) {
        console.error('❌ Login hatası:', error);
    }
};


  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 400, p: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>Giriş Yap</Typography>
        
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        
        <TextField
          fullWidth
          label="E-posta"
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          sx={{ mb: 2 }}
        />
        
        <TextField
          fullWidth
          label="Şifre"
          type="password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          sx={{ mb: 3 }}
        />
        
        <Button 
          fullWidth 
          variant="contained" 
          type="submit"
          sx={{ mb: 2 }}
        >
          Giriş Yap
        </Button>
        
        <Button 
          fullWidth 
          variant="text"
          onClick={() => navigate('/register')}
        >
          Hesap Oluştur
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
